$break-small: 480px;
$modal-width: 800px;
/*Item list page*/
// .modal {
//   width: 100vw;
// }

#order-service-modal {
  @import './service-list';
  @import './service-footer';
  @import './product-recommendations';

  margin: 0 auto;
  min-height: 100vh;
  min-height: 100dvh;
  width: $modal-width;
  max-width: 90%;
  transition: 0.2s width;

  &.bigger-width {
    width: 1300px;
  }

  .modal-content {
    margin: 0;
    border-radius: 0px;
    border: none;
    height: 100vh;
    height: 100dvh;
    overflow-y: scroll;
    overflow-x: hidden;

    .modal-body {
      // padding: 0 100px;
      padding: 0px 80px;

      @import 'modal-head';

      .order-service__wrapper {
        position: relative;
        transition: 0.4s;

        &.show-incentives {
          transform: translateX(calc(-100% - 100px));

          .order-service__content {
            opacity: 0;
            max-height: 100vh;
            max-height: 100dvh;
            overflow: hidden;
          }

          .order-service__incentives {
            opacity: 1;
          }
        }
      }

      .order-service__incentives {
        position: absolute;
        top: 0;
        left: calc(100% + 100px);
        width: 100%;
        height: calc(100vh - 115px);
        height: calc(100dvh - 115px);
        opacity: 0;
        transition: 0.4s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--content {
          position: relative;
          height: 100%;

          & > h1 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.4;
            margin-bottom: 56px;
          }
        }

        &--footer {
          position: sticky;
          bottom: 0;
          width: calc(100% + 200px);
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: #fff;
          margin-top: 60px;
          padding: 20px 100px;
          transform: translateX(-100px);
          box-shadow: 0 0 32px 4px rgba(0, 0, 0, 0.06);
          z-index: 1;

          button {
            margin-left: 20px;
            width: unset;
          }
        }
      }

      .order-service__content {
        position: relative;
        opacity: 1;
        transition: 0.4s;

        & > .title {
          margin-bottom: 20px;

          h3 {
            margin-bottom: 4px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.78;
            color: #0d1922;
          }

          // p {
          //   margin: 0;
          //   font-size: 12px;
          //   color: #505458;
          // }
        }

        .reason-list,
        .sub-reason {
          padding-bottom: 140px;

          > li:nth-child(1).reason {
            border-top: 0;
          }
          > li:nth-last-child(1).reason {
            border-bottom: 0;
          }
          li.reason,
          &-list__option {
            padding: 0;
            border-radius: 0;
            border-color: #f3f3f3;
            font-size: 16px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #33475b;
            transition: all 0.3s;
            position: relative;

            &.focus,
            &:hover {
              z-index: 1;
              // transform: scale(1.02);
              border-radius: 8px;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
            }

            &.focus {
              margin: 20px 0;
            }

            &:first-child.focus {
              margin-top: 0;
            }

            &:last-child.focus {
              margin-bottom: 0;
            }

            .reason-head {
              cursor: pointer;
              padding: 12px 10px;
              display: flex;
              justify-content: space-between;

              .icon {
                transition: 0.2s;
                &.down {
                  transform: rotate(90deg);
                }
              }
            }
            .reason-content {
              padding-top: 15px;

              button.continue-reason {
                width: 100%;
                max-width: 100%;
                i {
                  float: right;
                  margin-top: 3px;
                }
              }
            }
          }
          // li.reason[data-selected='true'] {
          //   .reason-head {
          //     color: #5a5bff;
          //   }
          // }
        }

        .sub-reason {
          &-list {
            display: flex;
            flex-direction: column;
            padding-left: 0;
            margin-bottom: 35px;
            list-style: none;

            &__option {
              width: 100%;
              cursor: pointer;
              padding: 12px 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: #fff;
              border-bottom: 1px solid #f3f3f3;
              transform-origin: bottom;

              &:last-of-type {
                border: none;
              }

              &:hover {
                position: relative;
                z-index: 1;
              }

              &--value {
                display: flex;
                align-items: center;
              }

              .sub-reason__option-title {
                margin-left: 10px;
              }

              .option-icon {
                font-size: 18px;
              }

              &.multi-select {
                justify-content: left;

                &:hover {
                  transform: none;
                  box-shadow: none;
                  position: static;
                }
              }
            }
          }
        }

        .quantity-selection {
          &__wrapper {
            text-align: left;
            align-items: center;
            margin: 0;

            .wrapper-dropdown {
              display: inline-block;
              transition: all 0.1s;

              label {
                font-size: 14px;
                font-weight: bold;
                color: #33475b;
                margin: 0;
                // margin-top: 10px;
              }
              .quantity-dropdown {
                width: 50px;
                margin-left: 10px;
                padding: 0;
                box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

                @include select-box-primary-border-color;

                &:hover:not(.show) {
                  border-bottom-color: var(
                    --theme__primary--color,
                    $primaryColor
                  );
                }

                button {
                  width: 100%;
                  background: #fff;
                  color: #0c131a;
                  font-size: 16px;
                  border: none;
                  position: relative;

                  &::after {
                    margin-left: 10px;
                  }
                  &:focus,
                  &:hover {
                    background-color: rgb(248, 248, 248);
                    box-shadow: none;
                  }
                }
                .dropdown-menu {
                  width: 100%;
                  min-width: 0px;
                  max-height: 340px;
                  overflow-y: scroll;
                  text-align: center;
                  padding: 0px;
                  margin: 0px;
                  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

                  &.show {
                    top: 2px !important;
                  }

                  &::-webkit-scrollbar-thumb {
                    background-color: var(
                      --theme__primary--color,
                      $primaryColor
                    );
                  }

                  .dropdown-item {
                    cursor: pointer;
                    padding: 5px 0px;

                    &:hover {
                      color: var(--theme__primary--color, #7c98b6);
                      // background-color: var(--theme__primary--color, #f8f8f8);
                    }

                    &:active,
                    &:focus {
                      outline: none;
                      color: #ffffff;
                      background-color: var(
                        --theme__primary--color,
                        $primaryColor
                      );
                    }
                  }
                }
              }
            }
          }
        }

        .service-mode {
          margin-bottom: 60px;

          &__frame {
            width: 596px;
          }

          &__quantitySelect {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .products-catalogue {
          .loading {
            height: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &__container {
            margin-bottom: 120px;
          }

          .mobile-only {
            display: none;
          }

          .desktop-only {
            display: block;
            background-color: #ffffff;
            box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.06);
          }

          .catalogFiltersContainer {
            position: sticky;
            top: 0;
            margin: -14px -100px 24px;
            padding: 14px 100px;
            background-color: #fff;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            z-index: 10;

            .applied-filter-mobile {
              display: none;
            }

            &::before {
              content: '';
              position: absolute;
              top: -10px;
              left: 0;
              height: 20px;
              width: 100%;
              background-color: #fff;
            }

            &__appliedFilters_mainwrappper {
              margin: 0 30px;
              display: flex;
              align-items: center;
            }

            &__appliedFilters_wrapper {
              position: relative;
              display: inline-block;
              width: 100%;

              .left-arrow,
              .right-arrow {
                cursor: pointer;
                position: absolute;
                display: inline-flex;
                // top: 8px;
                top: 0;
                background-color: white;
                height: 36px;
                width: 30px;
                align-items: center;
                justify-content: center;
                img {
                  height: 13px;
                }
              }

              .left-arrow {
                left: -21px;
                box-shadow: 4px 0px 4px 0 rgba(0, 0, 0, 0.08);
              }

              .right-arrow {
                right: -21px;
                box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.08);
              }
            }

            &__appliedFilters {
              position: relative;
              padding: 0;
              // margin: 8px 0;
              margin: 0;
              list-style: none;
              // display: flex;
              white-space: nowrap;
              overflow-x: hidden;

              width: 500px;

              li {
                border-radius: 16px;
                background-color: inherit;
                color: inherit;
              }

              li ~ li {
                margin-left: 5px;
              }

              &--item {
                display: flex;
                display: inline-flex;
                align-items: center;
                // padding: 4px 6px;
                padding: 0 9px;
                font-size: 11px;
                border-radius: 2px;
                border: solid 1px #eaf0f9;
                background-color: #f5f8fa;
                color: #0c131a;
                height: 36px;

                svg {
                  fill: inherit;
                  g {
                    fill: inherit;
                    path {
                      fill: inherit;
                    }
                  }
                }

                label {
                  margin: 0 12px 0 0;

                  &::after {
                    content: ':';
                  }
                }

                svg {
                  width: 16px;
                  height: 16px;
                  margin-left: 6px;
                  cursor: pointer;

                  &:hover {
                    & > g {
                      stroke: #555;
                    }
                  }
                }
              }
            }
          }

          .applied-filter-desktop {
            display: block;
          }

          .catalog-filters {
            display: flex;
            // justify-content: space-evenly;
            align-items: center;
            position: relative;

            .sort-filter {
              font-size: 12px;
              // margin: 0 0 0 20px;
              // min-width: 215px;
              // height: 32px;

              .custom-sortby-select {
                display: flex;
                align-items: center;
                height: 100%;
                padding: 8px 2px;
                border: 1px solid;
                border-color: inherit;
                border-radius: 4px;
                min-width: 215px;

                &__label {
                  display: flex;
                  align-items: center;
                  // flex-basis: 140px;
                  margin: 0 0 0 10px;

                  svg {
                    fill: inherit;
                    g {
                      fill: inherit;
                      path {
                        fill: inherit;
                      }
                    }
                  }
                }
                span {
                  // flex-basis: 80px;
                  margin: 0 0 0 7px;
                }
                select {
                  // width: 100%;
                  height: 100%;
                  border: none;
                  font-weight: bold;

                  &:focus {
                    outline: 0;
                    box-shadow: none;
                  }
                  option {
                    line-height: 20px;
                  }
                }
              }

              .css-1wa3eu0-placeholder {
                color: inherit;
              }

              .css-iyyqrh-control,
              .css-1m113zw-control {
                min-height: auto;
              }

              .css-yk16xz-control {
                background-color: inherit;
                // border-color: inherit;
                color: inherit;
              }

              // &__wrapper{
              //   display: flex
              // }

              // &__input{
              //   -moz-appearance:none; /* Firefox */
              //   -webkit-appearance:none; /* Safari and Chrome */
              //   appearance:none;
              //   border: none;
              // }

              // padding: 5px  8px;
              // border-radius: 4px;
              // border: solid 1px #d8916c;
              // background-color: #fff;
            }

            .catalog-exchange-footer {
              display: flex;
              justify-content: space-between;
              padding: 15px 10px;

              button {
                // background-color: initial;
                // color: inherit;
                border: none;
                // padding: 0;
                // font: inherit;
                cursor: pointer;
                outline: inherit;
              }
              &__clear {
                background-color: inherit;
                font-size: 12px;
                color: #0a131b;
                text-decoration: underline;
              }
              &__apply {
                font-size: 12px;
                color: white;
                padding: 9px 15px;
                border-radius: 4px;
                // background-color: #7bba84;
                @include primary-bg-color-var1;
              }
            }

            .catalog-filters-types {
              display: flex;
              font-size: 12px;

              .catalog-filter-type {
                flex-basis: 100px;
              }

              .selected-filter {
                flex: 1;
                padding: 10px;
                background-color: inherit;
                overflow-y: auto;
                height: 350px;
              }
            }

            .catalog-filter {
              border-bottom: solid 1px rgba(241, 137, 91, 0.1);
              padding: 10px;
              cursor: pointer;

              &.selected {
                background-color: inherit;
              }
            }

            .dropdown-menu {
              padding: 0;
            }

            .dropdown-menu.show {
              top: 0;
            }

            &__deepFilters {
              display: flex;
              // margin: 0 0 0 20px;
              border-radius: 4px;
            }

            .clear-filters {
              margin-left: 8px;
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                border: none;
                box-shadow: none;
                margin-left: 20px;
                font-size: 14px;
                text-decoration: underline;
                color: #888;
                padding: 0;
                background-color: transparent;
                outline: none;
                cursor: pointer;
              }
            }

            &__btn {
              padding: 0 10px;
              min-width: 87px;
              height: 36px;
              border-radius: 4px;
              box-shadow: 0 0 6px 0 #eaf0f9;
              border: solid 1px #eaf0f9;
              background-color: #ffffff;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 6px;
              cursor: pointer;
              outline: none;
              position: relative;

              &:active {
                transform: scale(0.98);
                box-shadow: 0 0 0 0 transparent;
              }

              &:disabled {
                transform: none;
                box-shadow: 0 0 0 0 transparent;
                background-color: #eee;
              }

              .filter-icon {
                display: flex;
                align-items: center;
                svg {
                  fill: inherit;
                  g {
                    fill: inherit;
                    path {
                      fill: inherit;
                    }
                  }
                }
              }

              svg {
                fill: inherit;
                g {
                  fill: inherit;
                  path {
                    fill: inherit;
                  }
                }
              }

              span {
                font-size: 12px;
                font-weight: 500;
                color: inherit;
              }

              &.highlight {
                background-color: #001135;
                border: 1px solid #001135;
                box-shadow: 0 0 6px 0 rgba(0, 17, 53, 0.3);

                &:disabled {
                  border: 1px solid #001135;
                  background-color: #001135;
                  opacity: 0.7;
                }

                span {
                  color: #fff;
                }
                svg > g {
                  fill: #fff;
                }
              }
            }

            &__title {
              font-size: 10px;
              color: #0c131a;
            }

            &__wrapper {
              width: 350px;
              border-radius: 0 2px 2px 2px;
              box-shadow: 0 0 6px 0 #eaf0f9;
              border: solid 1px #eaf0f9;
              background-color: #ffffff;
              // padding: 10px 10px 20px;
              transform: translateY(2px);

              .price-filter {
                $HANDLE-WIDTH: 15px;
                width: calc(100% - #{$HANDLE-WIDTH});
                margin-top: 5px;
                margin-left: $HANDLE-WIDTH / 2;

                .rheostat-progress {
                  position: absolute;
                  height: 4px;
                  border-radius: 2px;
                  background-color: #0c131a;
                  transform: translateY(-50%);
                }
                .rheostat-background {
                  height: 4px;
                  border-radius: 2px;
                  border: 1px solid #f2f2f2;
                  background-color: #e7e7e8;
                  transform: translateY(50%);
                }
                .rheostat-handle {
                  position: relative;
                  padding: 0;
                  z-index: 1;
                  width: $HANDLE-WIDTH;
                  height: 11px;
                  border-radius: 4px;
                  border: solid 1px #f2f2f2;
                  background-color: #fff;
                  box-shadow: 0 0 2px 0 rgba(128, 156, 184, 0.54);
                  transform: translate(-50%, -50%);
                  cursor: grab;
                  position: relative;

                  &::before {
                    content: '| | |';
                    position: absolute;
                    font-size: 4px;
                    color: #0c131a;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

                  &:hover {
                    cursor: grab;
                  }

                  &:active {
                    cursor: grabbing;
                  }
                }

                &__value {
                  width: calc(100% - #{$HANDLE-WIDTH});
                  margin-left: $HANDLE-WIDTH / 2;
                  margin-top: 8px;
                  display: flex;
                  justify-content: space-between;
                  font-size: 12px;
                  font-weight: 500;
                  color: #0c131a;
                  padding-bottom: 20px;
                  position: relative;

                  &--min {
                    transform: translateX(-($HANDLE-WIDTH / 2));
                  }
                  &--max {
                    transform: translateX(($HANDLE-WIDTH / 2));
                  }

                  &--currentMin,
                  &--currentMax {
                    position: absolute;
                    top: -30px;
                    transform: translateX(-50%);
                    font-size: 10px;
                  }
                }
              }

              .select-filter {
                // padding-top: 10px;
                // border-top: 1px solid #eaf0f9;
                &__wrapper {
                  border-radius: 2px;
                  border: solid 1px #eaf0f9;
                  background-color: #f5f8fa;
                  position: relative;
                }
                &__input {
                  display: inline-block;
                  height: 36px;
                  width: 100%;
                  padding: 0 32px 0 8px;
                  outline: none;
                  font-size: 12px;
                  border: none;
                  background-color: transparent;
                  color: #7c98b6;
                  appearance: none;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                &__selection-indicator {
                  pointer-events: none;
                  display: inline-block;
                  height: 16px;
                  width: 16px;
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }

            &__board {
              display: none;
              &.show {
                display: block;
              }
            }

            &__out-of-stock {
              // margin: 14px 0 0;
              // padding-top: 16px;
              // border-top: 1px solid #eaf0f9;

              &--label {
                font-size: 12px;
                color: #0c131a;
              }
            }

            .search-box__wrapper {
              display: flex;
              // gap: 10px;
            }

            .search-box {
              position: relative;
              display: flex;
              align-items: center;
              background-color: inherit;
              color: inherit;
              border-color: inherit;

              ::placeholder {
                /* Most modern browsers support this now. */
                color: inherit !important;
              }

              .search-icon {
                display: flex;
                align-items: center;
                background-color: inherit;
                color: inherit;
                border-color: inherit;

                svg {
                  fill: inherit;
                  g {
                    fill: inherit;
                    path {
                      fill: inherit;
                    }
                  }
                }
              }

              &__input {
                outline: none;
                height: 36px;
                width: 180px;
                border-radius: 4px;
                border: 1px solid #eaf0f9;
                padding: 8px 12px 8px 30px;
                font-size: 12px;
                // color: #7c98b6;

                &.right-padding {
                  padding-right: 30px;
                }

                &::placeholder {
                  color: #7c98b6;
                }

                &:disabled {
                  cursor: not-allowed;
                }
              }

              &__icon {
                position: absolute;
                width: 14px;
                height: 14px;

                &.find {
                  pointer-events: none;
                  left: 8px;
                }

                &.cross {
                  right: 8px;
                  cursor: pointer;
                  border-radius: 2px;

                  &:not(.disabled):hover {
                    background-color: #eee;
                  }

                  &.disabled {
                    cursor: not-allowed;
                  }
                }
              }

              &__button {
                cursor: pointer;
                padding: 0 16px;
                font-size: 12px;
                font-weight: 500;
                // color: #7c98b6;
                background-color: #eaf0f9;
                border: 1px solid #eaf0f9;
                transition: 0.2s border-color;
                height: 36px;
                margin: 0 0 0 10px;
                border-radius: 4px;

                &:not(:disabled):hover {
                  border-color: #c7d2e1;
                }

                &:not(:disabled):active {
                  transform: scale(0.98);
                }

                &:not(:disabled):focus,
                &:not(:disabled):active {
                  outline: none;
                }

                &:disabled {
                  opacity: 0.7;
                  cursor: progress;
                }
              }
            }
          }

          .catalog-list {
            * {
              margin: 0;
              padding: 0;
            }

            padding: 0;
            list-style: none;
            margin-bottom: -20px;
            margin-left: -24px;

            .catalog-item {
              margin-bottom: 24px;
              display: inline-block;
              width: calc((100% - 24px * 4) / 4);
              cursor: pointer;
              overflow: hidden;
              border-radius: 5px;
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
              transition: 0.2s;

              &:hover {
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
              }

              &:nth-of-type(2n + 1) {
                margin-left: 24px;
                margin-right: 24px;
              }

              &__image-wrapper {
                height: 230px;
                overflow: hidden;
                position: relative;

                img {
                  width: 100%;
                  position: relative;
                  z-index: 2;
                  height: 100%;
                  object-fit: contain;
                }
              }

              // &__image {
              //   z-index: 1;
              //   position: absolute;
              //   top: 0;
              //   left: 0;
              //   width: 100%;
              //   height: 100%;
              //   background-size: cover;
              //   background-repeat: no-repeat;
              //   background-position: center;
              //   filter: blur(60px) opacity(0.4);
              // }

              &__description {
                padding: 10px 10px;
                &-title {
                  // font-size: 13px;
                  // height: 18px;
                  // line-height: 18px;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                  // overflow-x: hidden;
                  // color: #33475b;
                  // position: relative;
                }

                &-variants {
                  font-size: 12px;
                  color: #7c98b6;
                }

                &-price {
                  font-size: 13px;
                  color: #33475b;
                }
              }
            }
          }

          .BrowseProductVariants {
            &.empty {
              min-height: 0;
            }

            .ProductVariant {
              width: 100%;
              // margin-bottom: 120px;

              &__image {
                height: 400px;
              }

              &__title {
                font-size: 24px;
              }

              // &__price {
              // font-size: 36px;

              // &-container {
              //   margin: 16px 0;
              // }
              // }

              &__quantity-indicator {
                font-size: 24px;
              }

              &__priceDiff {
                transform-origin: right center;
                transform: scale(1.3);
              }

              .attribute-label {
                margin-top: 16px;
              }

              .row {
                & > div:first-child {
                  // flex: 0 0 40%;
                  padding-left: 24px;
                }
                & > div:last-child {
                  // flex: 0 0 60%;
                  padding-left: 24px;
                }
              }

              .row.notification-wrapper {
                margin: 0;
              }

              .row.notification-wrapper .col {
                padding: 0;

                .trigger__notification {
                  padding: 12px 18px;
                }
              }

              #available-color-variant {
                div {
                  margin-right: 10px;
                }
              }

              .wrapper-dropdown button.dropdown-toggle {
                padding: 5px 0;
              }

              @media screen and (max-width: $break-small) {
                .row {
                  .col-lg-6 ~ .col-lg-6 {
                    padding-left: 0;
                  }
                }
              }
            }

            &__footer {
              // margin-top: 26px;
              @media screen and (max-width: $break-small) {
                margin-top: 10px;
              }
            }
          }

          ul.product-pagination {
            list-style: none;
            display: flex;
            justify-content: center;
            padding: 0;
            margin: 30px 0;

            li {
              font-size: 14px;
              margin: 0 2px;
              width: 28px;
              height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 3px;

              &.selected {
                background-color: var(--theme__primary--color, $primaryColor);

                a {
                  color: #fff;
                }
              }

              &.break {
                a {
                  line-height: 11px;
                }
              }

              &.disabled {
                a {
                  cursor: not-allowed;
                }
              }

              a {
                text-align: center;
                line-height: 20px;
                width: 20px;
                height: 20px;
                text-decoration: none;
                font-style: none;
                outline: none;
                color: #7c98b6;
                user-select: none;
                cursor: pointer;
              }

              // span {
              //   cursor: not-allowed;
              //   user-select: none;
              // }

              // .ais-Pagination-link {
              //   color: #7c98b6;
              //   border-radius: 3px;
              //   width: 28px;
              //   height: 28px;
              //   line-height: 28px;
              //   text-align: center;
              //   outline: none;

              //   &:hover {
              //     text-decoration: none;
              //   }

              //   &--selected {
              //     background-color: #001234;
              //     color: #fff;
              //   }
              // }
            }
          }

          .catalog-pd-footer {
            padding: 10px 0;
            font-size: 13px;
            border-radius: 3px;
            color: #7c98b6;

            i {
              border: 1px solid #7c98b6;
              color: #7c98b6;
              border-radius: 50%;
              display: inline-block;
              padding: 3px;
              width: 17px;
              height: 17px;
              text-align: center;
              font-size: 8px;
              margin: 5px 0px;
              position: relative;
              top: -2px;
              margin-right: 5px;
            }
          }

          .actions {
            margin: 0;

            button {
              margin: 0 !important;
            }
          }
        }

        // @media screen and (max-width: $break-small) {
        @media screen and (max-width: 600px) {
          padding: 15px;

          .products-catalogue {
            margin-bottom: 0;

            .mobile-only {
              display: block;
              // width: 100%;
              // position: fixed;
              // bottom: 0;
              // left: 0;
              // padding: 16px 15px;
              // background-color: #fff;
            }

            .desktop-only {
              display: none;
            }

            .catalogFiltersContainer {
              position: static;
              box-shadow: none;
              margin-bottom: 0;

              .applied-filter-mobile {
                display: block;
              }
              .applied-filter-desktop {
                display: none;
              }
            }

            .catalog-filters {
              $filter-height: 36px;
              flex-direction: row-reverse;
              margin-bottom: 12px;
              display: block;

              .sort-filter {
                margin: 0;
              }

              .clear-filters {
                display: none;
              }

              &__btn {
                height: $filter-height;
                min-width: 0;

                span {
                  // display: none;
                }
              }

              .select-filter {
                &__input {
                  flex-grow: 1;
                }
              }

              .search-box {
                width: 100%;
                // margin-right: 10px;
                &__input {
                  width: 100%;
                  height: $filter-height;
                  font-size: 14px;
                }
              }

              &__wrapper {
                width: 97vw;
                height: 80vh;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
              }

              .catalog-filters-types {
                flex: 1;
                .selected-filter {
                  height: 450px;
                }
              }

              .dropdown-menu {
                padding: 0;
                top: -85px !important;
                left: -245px !important;
                transform: none !important;
                z-index: 1000;
              }
            }

            .catalog-list {
              grid-template-columns: repeat(2, 1fr);
              grid-gap: 20px 10px;
              margin-left: 0;
              margin-right: 0;
              .catalog-item {
                $gap: 10px;
                width: calc(50% - 5px);
                margin-bottom: $gap;
                &:nth-of-type(2n-1) {
                  margin-right: 0;
                  margin-left: 0;
                }
                &:nth-of-type(2n) {
                  margin-left: $gap;
                }
                &__image {
                  height: 180px;
                }
              }
            }
          }

          .service-mode {
            margin-bottom: 36px;
          }
        }
        @import 'action-button';
        @import 'upload-photos';
        @import 'variant-tile';
        @import 'trigger-notification';
        @import 'product-variant';
      }
    }
  }
}

@media screen and (max-width: 700px) {
  #order-service-modal {
    .modal-content {
      .modal-body {
        padding: 0;
        .order-service__incentives {
          &--content {
            padding: 20px 20px;

            & > h1 {
              font-size: 22px;
              margin-bottom: 48px;
            }
          }
          &--footer {
            width: calc(100% + 180px);
          }
        }

        .order-service__content {
          .products-catalogue {
            .BrowseProductVariants {
              .ProductVariant {
                height: auto;
                margin-bottom: 20px;

                &__image {
                  height: 300px;
                  margin-bottom: 20px;
                }

                // &__title strong {
                //   font-size: 16px;
                // }

                &__price {
                  font-size: 24px;

                  &-container {
                    margin: 14px 0;
                  }
                }

                &__quantity-indicator {
                  font-size: 16px;
                }

                &__priceDiff {
                  transform: scale(1);
                }

                .attribute-label {
                  margin-top: 16px;
                }
              }
            }
          }
        }

        .products-catalogue {
          .catalogFiltersContainer {
            &__appliedFilters_mainwrappper {
              padding: 0 25px;
              margin: 15px 0 0 0 !important;
            }

            &__appliedFilters {
              width: auto !important;
            }
          }
        }

        .order-service__content {
          padding: 20px 15px;

          .service-mode {
            &__quantitySelect {
              position: static;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $break-small) {
  #order-service-modal {
    margin: 0;
    max-width: 100%;
    position: relative;

    .modal-content {
      .modal-body {
        .order-service__incentives {
          &--content {
            & > h1 {
              font-size: 18px;
              margin-bottom: 36px;
            }
          }
          &--footer {
            button:last-child {
              width: 176px;
            }
          }
        }

        .order-service__content {
          & > .title {
            h3 {
              // font-size: 20px;
              // font-weight: 400;
              padding-bottom: 10px;
              margin-bottom: 20px;
              border-bottom: 1px dashed #b1c4d8;
            }

            // p.text-muted {
            //   font-size: 1rem;
            // }
          }

          ul.reason-list > li.reason {
            &:hover {
              transform: none;
              box-shadow: none;
            }

            &.focus {
              margin: 20px -6px;
              // padding: 12px 10px;
            }

            .reason-head {
              padding: 12px 0;
            }

            &.focus .reason-head {
              padding: 12px 10px;
            }
          }

          .sub-reason {
            &-list {
              &__option {
                margin-left: -6px;
                margin-right: -6px;
                padding-left: 6px;
                padding-right: 6px;
              }
            }
          }
        }
      }
    }
  }
  // .modal-fix {
  // margin: 0.1rem;
  // }
}

// #service-modal-head {
//   @import 'modal-head';
// }
