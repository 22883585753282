@import './exchange-incentives';

$break-small: 480px;

.item-list {
  margin-top: 25px;
  .item {
    // background: #fff;
    // border: 1px solid #ecf1f4;
    border-radius: 6px;
    margin-bottom: 15px;
    transition: all 0.3s;

    .item-details {
      padding: 24px;
      $gap: 15px;

      @include primary-border-color-var3;
      @include br-4;

      &:hover {
        cursor: pointer;
        box-shadow: 0 12px 16px 0 rgba(30, 89, 133, 0.05);
      }

      .block-flex {
        display: flex;
        width: 100%;
        height: 100%;
      }

      .detail-wrapper {
        flex: 1;
      }

      .image-col {
        width: 150px;
        min-height: 135px;
        margin-right: $gap;
        &__image {
          height: 100%;
          border-radius: 3px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top center;
        }
      }

      .details-col {
        flex: 1;
        .title {
          margin-bottom: 5px;
          // font-size: 18px;
          // font-weight: 500;

          // line-height: 1.33;
          // color: $text-black-color;
          @media screen and (max-width: $break-small) {
            margin-top: 0px;
            font-size: 14px;
            // font-weight: 500;
            // line-height: 1.29;
            // color: #001234;
          }
        }
        // .attributes {
        // font-size: 15px;
        // line-height: 1.5;
        // color: $text-blue-secondary-color;
        // }
        .price {
          // font-size: 14px;
          // line-height: 1.5;
          // color: $text-blue-secondary-color;
        }
        .skuQuantity {
          color: #7c98b6;
        }
        .quantity {
          p.not-returnable {
            margin: 4px 0;
            color: #ad2b2b;

            a {
              color: inherit;
              font-weight: 500;
              text-decoration: underline;
            }
          }
        }
      }

      .action-col {
        // text-align: right;

        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        gap: 15px;

        position: relative;

        .delete-icon {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        .delete-icon-wrapper {
          padding: 8px 10px 8.7px;
          border: solid 1px rgba(12, 19, 26, 0.2);
          background-color: #fff;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          svg {
            height: 20px;
            width: 15px;
          }

          &:not(.disabled):hover {
            svg {
              g {
                fill: #da4d4d;
              }
            }
          }

          &.disabled {
            opacity: 0.5;
          }
        }

        button {
          font-size: 14px;
          font-weight: 500;
          width: 136px;
          height: 32px;
          &:focus {
            box-shadow: none;
          }
          &.selected-button {
            i {
              margin-left: 10px;
            }
            .remove {
              display: none;
            }
            &:hover {
              opacity: 0.8;
            }
            &:hover .remove {
              display: inline-block;
            }
            &:hover .text {
              display: none;
            }
          }
        }
        .message {
          // margin-top: 20px;
          // font-size: 14px;
          // line-height: 1.71;
          text-align: right;
          // color: var(--theme__primary--color, $primaryColor);
        }
      }

      .retex-alert-wrapper {
        width: 100%;
        display: flex;

        // .message {
        // font-size: 14px;
        // line-height: 1.23;
        // color: var(--theme__primary--color, $primaryColor);
        // }
      }

      // .discount-forward__wrapper {
      //   margin-top: $gap;
      // }

      @media screen and (max-width: 700px) {
        padding: 14px;

        .detail-wrapper > .block-flex {
          flex-direction: column;
        }

        .image-col {
          width: 20%;
          min-width: 70px;
        }

        .action-col {
          text-align: left;
          margin-top: 10px;

          .message {
            margin-top: 12px;
            text-align: left;
          }
        }
      }

      @media screen and (max-width: 500px) {
        .image-col {
          &__image {
            min-height: 100px;
          }
        }

        .details-col {
          .price {
            font-size: 13px;
            // line-height: 1.23;
            // color: #7c98b6;
            margin-bottom: 0px;
          }
          .quantity {
            > div {
              font-size: 13px;
              // line-height: 1.4;
              // color: #7c98b6;
            }
            p {
              font-size: 13px;
              // line-height: 1.23;
              // color: #33475b;
            }
            p.not-returnable {
              color: #ad2b2b;
            }
          }
        }
      }

      @media screen and (max-width: $break-small) {
        &.mobile-design {
          .action-col {
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.serviceable {
  padding-bottom: 20px;

  &.top-spacing {
    margin-top: 120px;

    @media (max-width: 480px) {
      margin-top: 160px;
    }
  }

  .selectedItems,
  .selectableItems {
    margin-top: 50px;

    & > p {
      color: #001232;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .item-list {
    margin-top: 0;
    // margin-top: 55px;
    // .item:hover {
    //   cursor: pointer;
    //   box-shadow: 0 12px 16px 0 rgba(30, 89, 133, 0.05);
    // }
    .item.dummy {
      opacity: 0;
      animation: unfold 0.6s 0.3s forwards;
    }
    .item.disabled,
    .item.dummy.disabled {
      pointer-events: none;
      opacity: 0.7;
      animation: none;
      // transform: scale(0.96);
    }

    .item.inactive {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  &__page-error {
    background: #ff7979;
    color: #fff;
  }
}

.not-serviceable {
  margin-top: 48px;
  .item {
    background-color: #f6fafd;

    .item-details:hover {
      cursor: default;
      box-shadow: none;
    }
  }
}

// @media screen and (max-width: $break-small) {
//   .mobile-card {
//     margin-left: 5px;
//   }
// }

@keyframes unfold {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
