.shipping-rates {
  margin-left: 0;
  margin-right: 0;

  &__row {
    border: 0;
    position: relative;
    align-items: center;

    &:hover {
      @include primary-bg-color-var4;
    }

    &.selected {
      @include primary-bg-color-var4;
    }
  }

  &__badge {
    position: absolute;
    top: 1px;
    background: url('../images/shipping-rate-badge.png');
    background-size: cover;
    color: #fff;
    width: 81px;
    height: 24px;
    padding-top: 6px;
    text-align: left;
    font-size: 11px !important;
    padding-left: 7px;
    margin-left: 0px;
    left: -2px;
    font-weight: 500;
    background-position: center;
  }

  &__changeLabelType {
    cursor: pointer;
    font-size: 12px;
    color: var(--theme__primary--color, $primaryColor);
    text-decoration: underline;
  }

  hr {
    border-top: 1px dotted #dae8ff;
    margin: 0px 1em;
  }

  &__content {
    padding-left: 0px;
    &-name {
      font-size: 14px;
      color: #001234;
      display: flex;
      align-items: center;

      @media screen and (max-width: $max-size) {
        flex-direction: column;
        align-items: flex-start;

        span {
          margin: 4px 0;
        }
      }
    }

    &-text {
      font-size: 12px;
      color: #7c98b6;
    }
  }

  &__rate {
    white-space: nowrap;
  }

  @media (max-width: 479px) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
